<ifr-page-title pageTitle="'LEFT_PANEL_PLAN' | translate " />
<div id="wid-plan-content-0">
  <plan
    api-url="'/WebPlanningAPI'"
    auth-token="authToken"
    locale="locale"
    user-id="userId"
    theme="theme"
    selected-plan="selectedPlan"
    licenses="licenses"
    on-auth-error="onAuthError"
    personnelNumberEnabled="personnelNumberEnabled"
    selectedPeople="selectedPeople"
    onSelectedPeopleChange="onSelectedPeopleChange"
    selectedBalanceSet="selectedBalanceSet"
    onSelectedBalanceSetChange="onSelectedBalanceSetChange"
    selectedShiftSet="selectedShiftSet"
    onSelectedShiftSetChange="onSelectedShiftSetChange"
    onModuleChange="onModuleChange"
    workflowRequestSubmissionAsSubstitute="workflowRequestSubmissionAsSubstitute"
  ></plan>
</div>
