<div class="widget-body-toolbar corrections-toolbar">
    <div class="row" iflx-affix="140" adjust-to=".corrections-affix" enable-mobile="false">
        <!-- Date selection -->
        <div class="corrections-md-margin-bottom col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <iflx-date-range-picker
                    single-date-picker="true"
                    initial-callback="true"
                    start-date="vm.selectedDate"
                    end-date="vm.selectedDate"
                    show-arrows="true">
            </iflx-date-range-picker>
        </div>

        <!-- Employee Selection -->
        <div class="corrections-md-margin-bottom col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <iflx-picker
                    class="corrections-employee-input"
                    data-picker-id="accountCorrectionsTransactionsPicker"
                    data-type="person"
                    data-multi-selection="false"
                    data-search-auto-focus="false"
                    data-combined-result="false"
                    data-sync-with-person-service="true"
                    data-persistent="true"
                    data-on-selection="vm.onPersonSelection"
                    data-activity-right="vm.activityRight"
                    data-search-context="accounting"
                    ng-hide="vm.isSimpleUser"
                    data-with-personnel-number="true"/>
        </div>

        <!-- Search criteria -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-search"></i></span>
                <input class="form-control person-search-input" type="text"
                       ng-model="searchCriteria" ng-change="vm.saveSearchCriteria()">
            </div>
        </div>
    </div>
</div>

<!-- Delete Selection Button -->
<div class="row" ng-show="vm.manualTransactions.length > 0">
    <div class="col-xs-12">
        <button ng-click="vm.deleteTransactions(null, false)"
                ng-class="{disabled: vm.selectCount == 0}"
                class="btn btn-sm btn-danger pull-right account-corrections-transactions-table-action-button">
            <span class="fa fa-trash-o"></span>
            <span>
                {{ 'ACCOUNT_CORRECTION_TRANSACTION_TABLE_ACTION_SELECTION_DELETE' | translate }}
                ({{vm.selectCount}})</span>
        </button>
    </div>
</div>


<form name="accountsForm" class="accountCorrectionsForm">
    <div class="account-corrections-transactions">
        <table st-table="vm.displayTransactions"
               st-responsive="vm.responsiveOptionsTransactions"
               st-safe-src="vm.transactions"
               st-class-ascent="st-sort-ascent"
               st-class-descent="st-sort-descent"
               st-func="stFuncB"
               id="account-transactions"
               class="table table-bordered dataTable hover table-hover"
               width="100%">
            <thead>
            <tr>
                <!--th st-sort="date" ng-hide="stFuncB.isHidden(0)">{{'ACCOUNT_CORRECTION_DATE' | translate}}</th-->
                <th st-sort="sourceAccount" st-hide="sourceAccount">
                    {{'ACCOUNT_CORRECTION_SOURCEACCOUNT' | translate}}
                </th>
                <th st-sort="targetAccount" st-hide="targetAccount">
                    {{'ACCOUNT_CORRECTION_TARGETACCOUNT' | translate}}
                </th>
                <th st-sort="amount" class="text-nowrap" st-hide="value">
                    <nobr>
                        {{'ACCOUNT_CORRECTION_VALUE' | translate}}
                        <span iflx-context-help="ACCOUNT_CORRECTION_TRANSACTION_VALUE_HELP"></span>
                    </nobr>
                </th>
                <th st-sort="unit" st-hide="transactionUnit">{{'ACCOUNT_CORRECTION_UNIT'
                    | translate}}
                </th>
                <th st-sort="category" class="text-nowrap" st-hide="category">
                    {{'ACCOUNT_CORRECTION_CATEGORY' | translate}}
                    <span iflx-context-help="ACCOUNT_CORRECTION_TRANSACTION_CATEGORY_HELP"></span>
                </th>
                <th st-sort="comment" st-hide="comment">{{'ACCOUNT_CORRECTION_COMMENT' |
                    translate}}
                </th>
                <th st-hide="actions" ng-show="vm.manualTransactions.length > 0" class="text-nowrap">
                    <nobr>
                        {{'ACCOUNT_CORRECTION_TABLE_ACTIONS' | translate}}
                        <span iflx-context-help="ACCOUNT_CORRECTION_TRANSACTION_ACTIONS_HELP"></span>
                    </nobr>
                </th>
                <th st-hide="checkboxes" class="text-nowrap account-corrections-transactions-table-textalign-center"
                    ng-show="vm.manualTransactions.length > 0">
                    <label class="account-corrections-transactions-table-checkbox">
                        <label></label>
                        <input type="checkbox" ng-model="vm.selectAll"
                               ng-change="vm.updateSelectAll()"/>
                    </label>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat-start="transaction in vm.displayTransactions | filter: vm.searchCriteriaFilter(searchCriteria)">
                <td st-hide="sourceAccount">
                    <!-- Expander icon -->
                    <span ng-show="$parent.stFuncB.showResponsiveRow()"
                          ng-click="stFuncB.toggleRowOpened(transaction)">
                        <span class="fa"
                              ng-class="{'fa-minus-square-o': $parent.stFuncB.isRowOpened(transaction),
                                         'fa-plus-square-o': !$parent.stFuncB.isRowOpened(transaction)}">
                        </span>
                    </span>
                    {{transaction.sourceAccount}}
                </td>
                <td st-hide="targetAccount"> {{transaction.targetAccount}}</td>
                <td ng-if="vm.service.isHourUnit( transaction )" st-hide="value"
                    class="account-transaction-value"> {{ transaction.amount }}
                </td>
                <td ng-if="!vm.service.isHourUnit( transaction )" st-hide="value"
                    class="account-transaction-value">{{ transaction.amount | iflxNumber
                    : 2 }}
                </td>
                <td st-hide="transactionUnit"> {{transaction.unit}}</td>
                <td st-hide="category"> {{ vm.getCategoryAndReason( transaction ) }}</td>
                <td st-hide="comment"> {{transaction.comment}}</td>
                <!-- Actions -->
                <td st-hide="actions" ng-show="vm.manualTransactions.length > 0"
                    class="account-corrections-transactions-table-textalign-center">
                    <button ng-click="vm.deleteTransactions( transaction, true )"
                            ng-show="transaction.isManual"
                            class="btn btn-sm btn-danger account-corrections-transactions-table-action-button">
                        <span class="fa fa-trash-o"></span>
                        <span>{{ 'ACCOUNT_CORRECTION_TRANSACTION_TABLE_ACTION_DELETE' | translate }}</span>
                    </button>
                </td>
                <!-- Checkboxes -->
                <td ng-show="vm.manualTransactions.length > 0" class="account-corrections-transactions-table-textalign-center">
                    <label ng-show="transaction.isManual">
                        <input type="checkbox" ng-model="transaction.selected"
                               ng-change="vm.updateSelectCount(transaction)"
                               class="account-corrections-transactions-table-checkbox"/>
                    </label>
                </td>
            </tr>

            <!-- responsive row -->
            <tr ng-repeat-end
                ng-show="stFuncB.showResponsiveRow() && stFuncB.isRowOpened(transaction)">
                <td colspan="{{stFuncB.colSpan()}}">

                    <div st-show="category">
                        <b>{{'ACCOUNT_CORRECTION_CATEGORY' | translate}}:</b>
                        {{ vm.getCategoryAndReason( transaction ) }}
                    </div>

                    <div st-show="comment">
                        <b>{{'ACCOUNT_CORRECTION_COMMENT' | translate}}:</b>
                        {{transaction.comment}}
                    </div>

                    <div st-show="value">
                        <div ng-if="vm.service.isHourUnit( transaction )">
                            <b>{{'ACCOUNT_CORRECTION_VALUE' | translate}}:</b>
                            {{ transaction.amount}}
                            <span st-show="transactionUnit">{{transaction.unit}}</span>
                        </div>

                        <div ng-if="!vm.service.isHourUnit( transaction )" >
                            <b>{{'ACCOUNT_CORRECTION_VALUE' | translate}}:</b>
                            {{ transaction.amount | iflxNumber : 2 }}
                            <span st-show="transactionUnit">{{transaction.unit}}</span>
                        </div>
                    </div>

                    <div st-show="actions" ng-show="transaction.isManual" class="pull-right">
                        <button ng-click="vm.deleteTransactions( transaction, true )"
                                class="btn btn-sm btn-danger account-corrections-transactions-table-action-button">
                            <span class="fa fa-trash-o"></span>
                            <span>{{ 'ACCOUNT_CORRECTION_TRANSACTION_TABLE_ACTION_DELETE' | translate }}</span>
                        </button>
                    </div>

                </td>
            </tr>

            <tr ng-if="!vm.employeeHasTransactions()">
                <td align="center" colspan="10">
                    <b>{{'ACCOUNT_CORRECTION_NO_TRANSACTIONS' | translate}}</b>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</form>
