<!--popover triangle-->
<div class="iflx-popover-triangle"></div>
<!--popover body-->
<div class="iflx-popover-tooltip">
    <div class="tt-event">
        <div  ng-if="tooltip === 'IF_tdf'"> {{ 'ACCESS_ELEMENT_TYPE_TERMINAL' | translate}}</div>
        <div  ng-if="tooltip === 'IF_raumzone'"> {{ 'ACCESS_ELEMENT_TYPE_ACCESSZONE' | translate }}</div>
        <div  ng-if="tooltip === 'IF_areal'"> {{ 'ACCESS_ELEMENT_TYPE_AREA' | translate }}</div>
        <div  ng-if="tooltip === 'IF_accprofdef'"> {{ 'ACCESS_ELEMENT_TYPE_ACCESSPROFILE' | translate }}</div>
        <div  ng-if="tooltip === 'IF_lockgroupdef'"> {{ 'ACCESS_ELEMENT_TYPE_NOCGROUP' | translate }}</div>
        <div  ng-if="tooltip === 'IF_lock'"> {{ 'ACCESS_ELEMENT_TYPE_NOCLOCK' | translate }}</div>
        <div  ng-if="tooltip === 'IF_lockprofiledef'"> {{ 'ACCESS_ELEMENT_TYPE_NOCPROFILE' | translate }}</div>
        <div  ng-if="tooltip === 'IF_contacts'"> {{ 'ACCESS_ELEMENT_TYPE_CONTACT' | translate }}</div>
    </div>
</div>
