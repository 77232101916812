<div class="recent-plans-widget-body iflx-widget-body">
  <div class="recent-plans-header">
    <span class="no-margin dashboard-widget-header">
      <i class="iflx-font iflx-font-tab iflx-font-planungsuebersicht"></i>
      {{ 'MOST_RECENTLY_OPENED_DISPATCH_SCHEDULE' | translate }}
    </span>
  </div>

  <div ng-show="!hasPerm" class="no-permission">
    {{ 'UNAUTHORIZED_MESSAGE_WIDGET' | translate }}.
  </div>
  <div ng-show="hasPerm">
    <recent-dispatch-schedule
      ng-if="isReady"
      api-url="'/WebPlanningAPI'"
      auth-token="authToken"
      locale="locale"
      theme="theme"
      on-plan-select="onPlanSelect"
      type="'6040'"
      module="'dispatchSchedule'"
      on-auth-error="onAuthError"
      retry-on-auth-error="retryOnAuthError"
    >
    </recent-dispatch-schedule>
  </div>
</div>
