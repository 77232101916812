<div data-jarvis-widget ng-attr-id="{{widgetInfo.id}}"
     data-widget-colorbutton="false" data-widget-togglebutton="false" data-widget-editbutton="false"
     data-widget-fullscreenbutton="false" data-widget-deletebutton="false" ng-hide="isVisible()"
     ng-class="{'isEmptyWidget': widgetInfo.widget == 'iflx-empty-widget-directive'}">
    <!-- widget options:
            usage: <div class="jarviswidget" id="wid-id-0" data-widget-editbutton="false">

            data-widget-colorbutton="false"
            data-widget-editbutton="false"
            data-widget-togglebutton="false"
            data-widget-deletebutton="false"
            data-widget-fullscreenbutton="false"
            data-widget-custombutton="false"
            data-widget-collapsed="true"
            data-widget-sortable="false"

        -->
    <header ng-show="isEdit">
        <h2><strong>{{widgetName()}}</strong></h2>

        <div class="widget-toolbar">
            <div class="btn-group">
                <button class="btn dropdown-toggle btn-xs btn-default" data-toggle="dropdown">
                    {{'WIDGET_SELECTIONLIST' | translate}}
                </button>

                <button class="btn btn-xs btn-danger widget-remove-button"
                        ng-click="updateWidget('iflx-empty-widget-directive')">
                    <i class="fa fa-trash-o margin-right-5"></i>{{'BUTTON_REMOVE' | translate}}
                </button>
                <ul class="dropdown-menu pull-right">
                    <li role="presentation" class="dropdown-header">
                        {{'LANG_HOME_WIDGETGROUP_ACTIONS' | translate}}
                    </li>

                    <li ng-repeat="widgetActions in favoriteWidgetActions | orderBy: 'name'"
                        ng-attr-id="{{widgetActions.id}}"
                        iflx-permission-show="{{widgetActions.permission}}">
                        <button
                          class="widget-selection-item"
                          ng-click="updateWidget(widgetActions.widgetType)">
                            {{widgetActions.name}}
                        </button>
                    </li>

                    <li role="presentation" class="divider"></li>

                    <li role="presentation" class="dropdown-header">{{'LANG_HOME_WIDGETGROUP_VIEWS' | translate}}</li>

                    <li ng-repeat="widgetLinks in favoriteWidgetLinks | orderBy: 'name'"
                        ng-attr-id="{{widgetLinks.id}}"
                        iflx-permission-show="{{widgetLinks.permission}}">
                        <button
                          class="widget-selection-item"
                          ng-click="updateWidget('iflx-favorites-widget-directive', widgetLinks.widgetType)">
                            {{widgetLinks.name}}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </header>

    <!-- widget div-->
    <div ng-class="{'widget-view-mode' :!isEdit}" class="widget-container-wrapper">

        <!-- widget edit box -->
        <div class="jarviswidget-editbox">
            <!-- This area used as dropdown edit box -->

        </div>
        <!-- end widget edit box -->

        <!-- widget content -->
        <div class="widget-body no-padding ">


        </div>
        <!-- end widget content -->

    </div>
    <!-- end widget div -->

</div>
