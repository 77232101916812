<div class="short-journal-widget-body iflx-widget-body AT_widget_journal">
    <div class="short-journal-header">
        <span class="no-margin dashboard-widget-header"><i class="iflx-font iflx-font-tab iflx-font-monatsjournal"></i>
            {{'LANG_SHORT_JOURNAL' | translate}}
            <button class="accessibility-button widget-reload" ng-show="hasPerm()" ng-click="getData()" refresh-button>
                <i class="fa fa-refresh"></i>
                <span visually-hidden>{{'ACCESSIBILITY_DASHBOARD_WIDGET_REFRESH' | translate}} {{'LANG_SHORT_JOURNAL' | translate}}</span>
            </button>
        </span>
    </div>

    <div ng-show="!hasPerm()" class="no-permission">{{'UNAUTHORIZED_MESSAGE_WIDGET' | translate}}.</div>
    <div class="no-background" ng-show="hasPerm()">
        <div class="short-journal-list">
            <div ng-show="loadingState">{{ loadingState }}</div>

            <!-- Table with days -->
            <table ng-hide="loadingState" class="short-journal-table">
                <tbody>
                <tr ng-repeat="row in rows | orderBy:'day':true" iflx-on-ng-repeat-finished="adjustSize()" class="short-journal-table-row">
                    <td class="short-journal-table-data">
                        <span ng-if="isTodayOrYesterday(row.day)"><b>{{getUserFriendlyDay(row.day) }}</b></span>
                        <span ng-if="!isTodayOrYesterday(row.day)"><b>{{row.day | date: 'EEE'}} {{getUserFriendlyDay(row.day) }}</b></span>

                        <!-- Table with journal rows -->
                        <table>
                            <tbody>
                            <tr ng-repeat="entry in row.entries">
                                <td class="short-journal-table-data-times">
                                    <!-- Warning Icon -->
                                    <span class="fa fa-warning short-journal-incident-icon"
                                          ng-class="{'short-journal-incident-icon-hidden': !entryHasIncidents(entry)}">
                                    </span>
                                </td>
                                <td class="short-journal-table-data-times" colspan="4"
                                    ng-if="!entry.start.time && !entry.end.time && entry.rowIncidents.length == 0">
                                    <i >
                                        {{'JOURNAL_NO_BOOKINGS' | translate }}
                                    </i>
                                </td>
                                <!-- start time -->
                                <td class="short-journal-table-data-times short-journal-incident short-journal-time"
                                    ng-class="entry.timeClass"
                                    ng-if="entry.start.time || entry.end.time">
                                    <span ng-class="{'short-journal-incident-times': entry.start.incidents.length > 0 || entry.start.isSuccessfull === false}">
                                        {{entry.start.time | time }}
                                    </span>
                                </td>
                                <!-- separator (displayed red, if start-time and end-time both have incidents)-->
                                <td class="short-journal-table-data-times short-journal-separator" ng-hide="entry.start.isNoTaBooking"
                                    ng-if="entry.start.time || entry.end.time"
                                    ng-class="{'short-journal-incident-times': (entry.start.incidents.length > 0 || entry.start.isSuccessfull === false)
                                               && (entry.end.incidents.length > 0 || entry.end.isSuccessfull === false)}"
                                >
                                    <span class="short-journal-table-data-times-separator"
                                          ng-if="entry.start.time && entry.end.time" > - </span>
                                </td>
                                <!-- end time -->
                                <td class="short-journal-table-data-times short-journal-time short-journal-end-time"
                                    ng-class="entry.timeClass"
                                    ng-if="entry.end.time || entry.start.time">
                                    <span ng-class="{'short-journal-incident-times': entry.end.incidents.length > 0 || entry.end.isSuccessfull === false}">
                                            {{entry.end.time | time }}
                                    </span>
                                </td>

                                <!-- incident times -->
                                <!-- incident start time -->
                                <td class="short-journal-table-data-times short-journal-incident-times short-journal-time"
                                    ng-if="entry.hasRowIncidents && entry.hasRowIncidentWithTimes && getRowIncidentTimes(entry)[0]">
                                    <span>{{getRowIncidentTimes(entry)[0]}}</span>
                                </td>
                                <!-- incident times separator -->
                                <td class="short-journal-table-data-times short-journal-incident-times"
                                    ng-if="entry.hasRowIncidents && entry.hasRowIncidentWithTimes
                                    && getRowIncidentTimes(entry)[0] && getRowIncidentTimes(entry)[1]">
                                    <span class="short-journal-table-data-times-separator"> - </span>
                                </td>
                                <!-- incident end time -->
                                <td class="short-journal-table-data-times short-journal-incident-times short-journal-time short-journal-end-time"
                                    ng-if="entry.hasRowIncidents && entry.hasRowIncidentWithTimes && getRowIncidentTimes(entry)[1]">
                                    <span>{{getRowIncidentTimes(entry)[1]}}</span>
                                </td>

                                <!-- incidents texts for incidents without times -->
                                <td class="short-journal-table-data-times short-journal-table-data-padding" colspan="4"
                                    ng-if="entry.hasRowIncidents && !entry.hasRowIncidentWithTimes">
                                    <span class="short-journal-incident-text short-journal-incident-text-no-times">{{getRowIncidentText(entry)}}</span>
                                </td>

                                <td class="short-journal-table-data-text"
                                    ng-if="entry.hasRowIncidents && entry.hasRowIncidentWithTimes">
                                  <span class="short-journal-incident-text">{{getRowIncidentText(entry)}}</span>
                                </td>

                                <td class="short-journal-table-data-text"
                                    ng-if="getIncidentText(entry) !== '' || entry.liveTrackingInfo">
                                    <span ng-if="getIncidentText(entry) !== ''" ng-class="{'short-journal-incident-text': entryHasIncidents(entry),
                                    'short-journal-state-reason-text': entry.stateReason !== null}">{{getIncidentText(entry)}}
                                    </span><span ng-if="getIncidentText(entry) !== '' && entry.liveTrackingInfo" >, </span>
                                    <span ng-class="{'short-journal-livetracking-text-no-incidents': getIncidentText(entry) === ''}">{{entry.liveTrackingInfo}}</span>
                                </td>

                                <td ng-if="getEventType(entry) !== ''">
                                    <span class="margin-left-10">{{getEventType(entry)}}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>

                  <td class="short-journal-link-column">
                    <a class="short-journal-link"
                       ng-class="{'short-journal-link-hidden': !rowHasIncidents(row)}"
                       ng-click="goToTimeCorrections(row.day)">
                      <i class="fa fa-external-link"
                         aria-hidden="false"
                         aria-label="{{'JOURNAL_LINK_TO_TIME_CORRECTIONS' | translate}}"></i>
                    </a>
                  </td>

                  </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="margin-top-10 indent" ng-hide="loadingState|| (rows || !hasPerm())">{{'NO_ENTRIES' | translate}}</div>
</div>

