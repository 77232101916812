<ifr-page-title pageTitle="'LEFT_PANEL_ACCESSRIGHTSMANAGEMENT' | translate "/>
<section data-widget-grid id="access-rights-management-view">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
            <div class="jarviswidget jarviswidget-color-darken" id="wid-access-rights-management-0">

                <header>
                    <span class="widget-icon" aria-hidden="true">
                        <i class="iflx-font iflx-font-inline iflx-font-zutrittsrecht"></i>
                    </span>

                    <h2>{{ 'LANG_ACCESSRIGHTSMANAGEMENT_HEADER' | translate }}</h2>
                </header>

               <div class="access-rights-management-view">
                   <div class="row">
                        <!-- Date Range Selection-->
                        <div class="access-rights-md-margin-bottom access-rights-top-padding-correction col-xs-12 col-sm-12 col-md-12 col-lg-3">
                            <iflx-date-range-picker
                                show-arrows="true"
                                single-date-picker="false"
                                start-date="vm.accessRightsManagementService.selectedStartDate"
                                end-date="vm.accessRightsManagementService.selectedEndDate"
                                maximum="vm.accessRightsManagementService.maximumEndDate"
                                ranges="vm.accessRightsManagementService.dateRanges"
                                show-week-numbers="true"
                                on-date-change="vm.onDateChange()"
                                input-id="dateRangePicker1">
                            </iflx-date-range-picker>
                        </div>

                        <!-- Employee Selection -->
                        <div class="access-rights-md-margin-bottom col-xs-12 col-sm-12 col-md-12 col-lg-3" ng-hide="vm.isSimpleUser">
                            <iflx-picker
                                ng-model="vm.selectedEmployee"
                                class="corrections-employee-input"
                                data-picker-id="accessRightsManagementPicker"
                                data-type="person"
                                data-multi-selection="false"
                                data-search-auto-focus="false"
                                data-combined-result="false"
                                data-sync-with-person-service="true"
                                data-persistent="true"
                                data-on-selection="vm.onPickerSelection"
                                data-activity-right="vm.activityRight"
                                data-with-personnel-number="true">
                            </iflx-picker>
                          <!--todo set search-context to access or remove it?-->
                        </div>

                       <!-- Toolbar Buttons -->
                       <div class="access-rights-button-area access-rights-md-margin-bottom col-xs-12 col-sm-12 col-md-12" ng-class="{'col-lg-9': vm.isSimpleUser, 'col-lg-6': !vm.isSimpleUser}">
                           <!--add Access Right btn-->
                           <div ng-class="{'disabled': !vm.addAccessRightButtonState}"
                              class="btn btn-default btn-sm access-btn access-rights-management-toolbar-button-positioning"
                              ng-click="vm.showAddAccessRightModal()">
                               <i class="fa fa-plus"></i>
                               <span class="hidden-xs">{{ 'ACCESS_RIGHT_BUTTON_ADD_ACCESS_ELEMENT' | translate }}</span>
                           </div>
                           <!-- Single Download Button-->
                           <div class="btn btn-default btn-sm access-btn access-rights-management-toolbar-button-positioning"
                                ng-click="vm.startSingleDownloadWithRight()"
                                ng-show="vm.hasSingleDownloadRight == true">
                               <i class="fa fa-download"></i>
                               <span class="hidden-xs">{{'ACCESS_RIGHT_BUTTON_SINGLE_DOWNLOAD' | translate }}</span>
                           </div>
                           <!-- Save Access Rights Button-->
                           <div ng-class="{'disabled': !vm.accessRightsManagementService.modifiedPersonalAccessRights.length > 0 && !vm.accessRightsManagementService.modifiedOrgUnitAccessRights.length > 0,
                                            'btn-success': vm.accessRightsManagementService.modifiedPersonalAccessRights.length > 0 || vm.accessRightsManagementService.modifiedOrgUnitAccessRights.length > 0,
                                            'btn-default': !(vm.accessRightsManagementService.modifiedPersonalAccessRights.length > 0 || vm.accessRightsManagementService.modifiedOrgUnitAccessRights.length > 0)}"
                                class="btn btn-sm access-btn access-rights-management-toolbar-button-positioning"
                                ng-click="vm.saveModifiedData()">
                               <i class="fa fa-check"></i>
                               {{'BUTTON_SAVE' | translate}}
                           </div>
                           <!-- Undo Changes/Revert Button -->
                           <div ng-class="{'disabled': !vm.accessRightsManagementService.modifiedPersonalAccessRights.length > 0 && !vm.accessRightsManagementService.modifiedOrgUnitAccessRights.length> 0}" ng-click="vm.revertChanges()"
                                class="btn btn-default btn-sm access-btn access-rights-management-toolbar-button-positioning">
                               <i class="fa fa-reply"></i>
                               {{'BUTTON_REVERT' | translate}}
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 personal-access-rights">
          <div class="jarviswidget jarviswidget-color-darken" id="wid-access-rights-management-1">
            <div class="access-rights-management-view">
              <form name="accessRightsForm" unsaved-warning-form>
                <div class="access-rights-management-table-header">
                  <h6>{{'PERSONAL_ACCESS_RIGHT_TABLE_LABEL' | translate }}</h6>
                </div>
                <table  st-table="vm.accessRightsManagementService.displayPersonalAccessRights"
                        st-safe-src="vm.accessRightsManagementService.backupPersonalAccessRights"
                        st-responsive="vm.responsiveOptionsPersonalTable"
                        st-class-ascent="st-sort-ascent"
                        st-class-descent="st-sort-descent"
                        st-func="stFunc"
                        id="personal-access-rights-table"
                        class="row-border hover table table-bordered table-hover access-rights-management-table-td"
                        ng-class="{'responsive': stFunc.showResponsiveRow()}"
                        width="100%"
                >
                  <thead>
                  <tr>
                    <th st-hide="accessElement">
                      {{'ACCESS_RIGHT_TABLE_ACCESS_ELEMENT' | translate}}
                    </th>
                    <th st-hide="timeProfile" class="access-rights-management-table-time-profile">
                      {{'ACCESS_RIGHT_TABLE_TIME_PROFILE' | translate}}
                    </th>
                    <th st-hide="validFrom" class="access-rights-management-time-td">
                      {{'ACCESS_RIGHT_TABLE_VALID_FROM' | translate}}
                    </th>
                    <th st-hide="validTo" class="access-rights-management-time-td">
                      {{'ACCESS_RIGHT_TABLE_VALID_TO' | translate}}
                    </th>
                    <th st-hide="grantedBy" class="access-rights-management-table-granted-by">
                      {{'ACCESS_RIGHT_TABLE_GRANTED_BY' | translate}}
                    </th>
                    <th st-hide="grantedOn" class="access-rights-management-time-td">
                      {{'ACCESS_RIGHT_TABLE_GRANTED_ON' | translate}}
                    </th>
<!--                    <th st-hide="companion" class="access-rights-management-table-companion">
                      {{'ACCESS_RIGHT_TABLE_COMPANION' | translate}}
                    </th>-->
                    <th st-hide="comment" class="access-rights-management-table-comment">
                      {{'ACCESS_RIGHT_TABLE_COMMENT' | translate}}
                    </th>
                    <th st-hide="accessZone" class="access-rights-management-table-checkbox">
                      {{'ACCESS_RIGHT_TABLE_ACCESS_ZONE' | translate}}
                    </th>
                    <th st-hide="active" class="access-rights-management-table-checkbox">
                      {{'ACCESS_RIGHT_TABLE_ACTIVE' | translate}}
                    </th>
                    <th st-hide="actions" class="access-rights-management-table-checkbox">
                      {{'ACCESS_RIGHT_TABLE_ACTIONS' | translate}}
                    </th>
                  </tr>
                  </thead >
                  <tbody>
                  <tr ng-repeat-start="accessRight in vm.accessRightsManagementService.displayPersonalAccessRights | orderBy:'accessElement.name'" ng-init="tooltip = accessRight.accessElement.typeId">
                    <td st-hide="accessElement" iflx-popover
                        template="app/accessRightsManagement/partials/accessRightTableTooltip.html"
                        theme="iflx-tooltip"
                        trigger="mouseover"
                        trigger-timeout="0.4"
                        placement="bottom|left"
                        timeout="0.1"
                        hide-others-on-open="true"
                        hide-on-click="false"
                        support-toast="true">
                      <table>
                        <tr>
                          <td >
                            <!-- Expander icon -->
                            <span ng-show="stFunc.showResponsiveRow()"

                                  ng-click="stFunc.toggleRowOpened(accessRight)">
                                                                  <span ng-class="{'fa-minus-square-o': $parent.stFunc.isRowOpened(accessRight),
                                                                  'fa-plus-square-o': !$parent.stFunc.isRowOpened(accessRight)}"
                                                                        class="fa account-expander">
                                                                  </span>
                                                      </span>
                          </td>
                          <td>
                            {{accessRight.accessElement.name}}
                            <i ng-show="accessRight.isTaskflowActive" class="iflx-font iflx-font-tab iflx-font-absence-time-requests"></i>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td st-hide="timeProfile">
                      {{accessRight.timeProfile.name}}
                    </td>
                    <td st-hide="validFrom" class="access-rights-management-time-td">
                      <span ng-hide="vm.shouldShowEmptyDateField(accessRight.validFrom)">
                        <i class="fa fa-calendar"></i> {{accessRight.validFrom.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                        <br />
                        <i class="fa fa-clock-o"></i> {{accessRight.validFrom | date:'shortTime'}}
                      </span>
                    </td>
                    <td st-hide="validTo" class="access-rights-management-time-td">
                      <span ng-hide="vm.shouldShowEmptyDateField(accessRight.validTo)">
                        <i class="fa fa-calendar"></i> {{accessRight.validTo.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                        <br />
                        <i class="fa fa-clock-o"></i> {{accessRight.validTo | date:'shortTime'}}
                      </span>
                    </td>
                    <td st-hide="grantedBy">
                      <i class="fa fa-user" ng-hide="!accessRight.grantedBy"></i> {{accessRight.grantedBy}}
                    </td>
                    <td st-hide="grantedOn" class="access-rights-management-time-td">
                      <i class="fa fa-calendar" ng-hide="!accessRight.grantedOn"></i> {{accessRight.grantedOn.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                      <br />
                      <i class="fa fa-clock-o" ng-hide="!accessRight.grantedOn"></i> {{accessRight.grantedOn | date:'shortTime'}}
                    </td>
<!--                    <td st-hide="companion">
                      {{accessRight.companion.displayName}}
                    </td>-->
                    <td st-hide="comment">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" title="{{accessRight.grantedWithComment}}">
                          {{accessRight.grantedWithComment}}
                        </span>
                      </span>
                    </td>
                    <td st-hide="accessZone" align="center" valign="middle">
                      <input type="checkbox" ng-model="accessRight.accessZoneActive" ng-change="vm.changeActive(accessRight)"
                             ng-disabled="!(accessRight.executionRightValue >= vm.executionRightValue.ChangePerm)">
                    </td>
                    <td st-hide="active" align="center" valign="middle">
                      <input type="checkbox" ng-model="accessRight.active" ng-change="vm.changeActive(accessRight)"
                             ng-disabled="!(accessRight.executionRightValue >= vm.executionRightValue.ChangePerm)">
                    </td>
                    <!-- Account columns action btn-->
                    <td st-hide="actions" class="access-rights-management-actions">
                      <div ng-class="{disabled: !(accessRight.executionRightValue >= vm.executionRightValue.ChangePerm)}"
                           class="btn  btn-xs btn-success" tabindex="-1" ng-click="vm.showAddAccessRightModal(accessRight)">
                        <span class="fa fa-pencil-square-o"></span>
                      </div>
                      <div ng-class="{disabled: !(accessRight.executionRightValue == vm.executionRightValue.YesPerm)}"
                           class="btn btn-xs btn-danger red" tabindex="-1" ng-click="vm.accessRightsManagementService.deleteAccessRight(accessRight)">
                        <span class="fa fa-trash-o"></span>
                      </div>
                    </td>
                  </tr>
                  <!--Hidden Responsive Area-->
                  <tr ng-repeat-end class="responsive-row"
                      ng-show="stFunc.showResponsiveRow() && stFunc.isRowOpened(accessRight)">

                    <td colspan="{{stFunc.colSpan()}}">

                      <div class="hiddenResponsiveAreaPositionLeft">
                        <div st-show="validFrom">
                          <span><b> {{'ACCESS_RIGHT_TABLE_VALID_FROM' | translate}}: </b></span>
                        </div>
                        <div st-show="validTo">
                          <span><b> {{'ACCESS_RIGHT_TABLE_VALID_TO' | translate}}: </b></span>
                        </div>
                        <div st-show="grantedBy">
                          <span><b> {{'ACCESS_RIGHT_TABLE_GRANTED_BY' | translate}}: </b></span>
                        </div>
                        <div st-show="grantedOn">
                          <span><b> {{'ACCESS_RIGHT_TABLE_GRANTED_ON' | translate}}: </b></span>
                        </div>
<!--                        <div st-show="companion">
                          <span><b> {{'ACCESS_RIGHT_TABLE_COMPANION' | translate}}: </b></span>
                        </div>-->
                        <div st-show="accessZone" class="hiddenResponsiveAreaAccessZoneAndComment">
                          <span><b> {{'ACCESS_RIGHT_TABLE_ACCESS_ZONE' | translate}}: </b></span>
                        </div>
                        <div st-show="comment" class="hiddenResponsiveAreaAccessZoneAndComment" ng-hide="!accessRight.grantedWithComment">
                          <span><b> {{'ACCESS_RIGHT_TABLE_COMMENT' | translate}}: </b></span>
                        </div>
                      </div>

                      <div class="hiddenResponsiveAreaPositionRight">
                        <div st-show="validFrom">
                          <span ng-show="vm.shouldShowEmptyDateField(accessRight.validFrom)">
                           <br />
                          </span>
                         <span ng-hide="vm.shouldShowEmptyDateField(accessRight.validFrom)">
                            <i class="fa fa-calendar"></i> {{accessRight.validFrom.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                            <i class="fa fa-clock-o"></i> {{accessRight.validFrom | date:'shortTime'}}
                          </span>
                        </div>
                        <div st-show="validTo">
                          <span ng-show="vm.shouldShowEmptyDateField(accessRight.validTo)">
                           <br />
                          </span>
                          <span ng-hide="vm.shouldShowEmptyDateField(accessRight.validTo)">
                            <i class="fa fa-calendar"></i> {{accessRight.validTo.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                            <i class="fa fa-clock-o"></i> {{accessRight.validTo | date:'shortTime'}}
                          </span>
                        </div>
                        <div st-show="grantedBy">
                          <span>
                            <i class="fa fa-user"></i> {{accessRight.grantedBy}}
                          </span>
                        </div>
                        <div st-show="grantedOn">
                          <span>
                            <i class="fa fa-calendar"></i> {{accessRight.grantedOn.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                            <i class="fa fa-clock-o"></i> {{accessRight.grantedOn | date:'shortTime'}}
                          </span>
                        </div>
<!--                        <div st-show="companion">
                          <span>
                            <i class="fa fa-user"></i> {{accessRight.companion.displayName}}
                          </span>
                        </div>-->
                        <div st-show="accessZone">
                          <input type="checkbox" ng-model="accessRight.accessZoneActive" ng-change="vm.changeActive(accessRight)"
                                 ng-disabled="!(accessRight.executionRightValue >= vm.executionRightValue.ChangePerm)">
                        </div>
                        <div st-show="comment" ng-hide="!accessRight.grantedWithComment">
                          <span> {{accessRight.grantedWithComment}} </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!-- Show "No Data" if there are no access rights-->
                  <tr ng-if="!vm.accessRightsManagementService.displayPersonalAccessRights.length">
                    <td align="center" colspan="10">
                      <b>{{'LANG_NODATA' | translate}}</b>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 inherited-access-rights">
            <div class="jarviswidget jarviswidget-color-darken" id="wid-access-rights-management-2">
                <div class="access-rights-management-view">
                    <form name="inheritedAccessRightsForm" unsaved-warning-form>
                        <div class="access-rights-management-table-header">
                            <h6>{{ 'INHERITED_ACCESS_RIGHT_TABLE_LABEL' | translate }}</h6>
                        </div>
                            <table class="row-border hover table table-bordered table-hover inherited-access-rights-table-td"
                                   id="orgUnit-access-rights-table"
                                   st-table="vm.accessRightsManagementService.displayedGroups"
                                   st-safe-src="vm.accessRightsManagementService.backupGroups"
                                   st-responsive="vm.responsiveOptionsOrgUnitTable"
                                   st-class-ascent="st-sort-ascent"
                                   st-class-descent="st-sort-descent"
                                   ng-class="{'responsive': stFunc.showResponsiveRow()}"
                                   st-func="stFunc"
                                   width="100%">
                              <thead>
                              <tr>
                                <th st-sort="accessElement" st-hide="accessElement">
                                  {{ 'ACCESS_RIGHT_TABLE_ACCESS_ELEMENT' | translate }}
                                </th>
                                <th st-hide="timeProfile" class="access-rights-management-table-time-profile">
                                  {{ 'ACCESS_RIGHT_TABLE_TIME_PROFILE' | translate }}
                                </th>
                                <th st-hide="validFrom" class="access-rights-management-time-td">
                                  {{ 'ACCESS_RIGHT_TABLE_VALID_FROM' | translate }}
                                </th>
                                <th st-hide="validTo" class="access-rights-management-time-td">
                                  {{ 'ACCESS_RIGHT_TABLE_VALID_TO' | translate }}
                                </th>
                                <th st-hide="grantedBy" class="access-rights-management-table-granted-by">
                                  {{ 'ACCESS_RIGHT_TABLE_GRANTED_BY' | translate }}
                                </th>
                                <th st-hide="grantedOn" class="access-rights-management-time-td">
                                  {{ 'ACCESS_RIGHT_TABLE_GRANTED_ON' | translate }}
                                </th>
                                <th st-hide="comment" class="access-rights-management-table-comment">
                                  {{ 'ACCESS_RIGHT_TABLE_COMMENT' | translate }}
                                </th>
                                <th st-hide="personProperty" class="access-rights-management-table-person-property">
                                  {{ 'ORG_UNIT_ACCESS_RIGHT_TABLE_PERSON_PROPERTY' | translate}}
                                </th>
                                <th st-hide="accessZone" class="access-rights-management-table-checkbox">
                                  {{ 'ACCESS_RIGHT_TABLE_ACCESS_ZONE' | translate }}
                                </th>
                                <th st-hide="active" class="access-rights-management-table-checkbox">
                                  {{ 'ACCESS_RIGHT_TABLE_ACTIVE' | translate }}
                                </th>
                              </tr>
                              </thead>
                              <tbody>

                              <!-- Groups -->
                              <tr ng-repeat-start="group in vm.accessRightsManagementService.displayedGroups"
                                  ng-click="vm.toggleGroup(group)"
                                  ng-show="group.orgUnitAccessRights">

                                <!-- Group Header -->
                                <td colspan="10" class="orgUnit-header">
                                  <i class="fa fa-sitemap inherited-access-rights-group-i"></i>
                                  {{::group.name}}
                                  <span class="fa fa-lg orgUnit-arrow"
                                        ng-class="{'fa-angle-up': group.isClosed,
                                                  'fa-angle-down': !group.isClosed}"></span>
                                </td>
                              </tr>

                              <!-- Group orgUnitAccessRights -->
                              <tr class="inherited-access-rights-table-tr" ng-if="!group.isClosed" ng-repeat-start="orgUnitAccessRight in group.orgUnitAccessRights" ng-init="tooltip = orgUnitAccessRight.personalAccessRight.accessElement.typeId">
                                <td st-hide="accessElement"
                                    iflx-popover
                                    template="app/accessRightsManagement/partials/accessRightTableTooltip.html"
                                    theme="iflx-tooltip"
                                    trigger="mouseover"
                                    trigger-timeout="0.4"
                                    placement="bottom|left"
                                    timeout="0.1"
                                    hide-others-on-open="true"
                                    hide-on-click="false"
                                    support-toast="true">
                                  <table>
                                    <tr>
                                      <td >
                                        <!-- Expander icon -->
                                        <span ng-show="stFunc.showResponsiveRow()"

                                              ng-click="stFunc.toggleRowOpened(orgUnitAccessRight.personalAccessRight)">
                                                                  <span ng-class="{'fa-minus-square-o': $parent.stFunc.isRowOpened(orgUnitAccessRight.personalAccessRight),
                                                                  'fa-plus-square-o': !$parent.stFunc.isRowOpened(orgUnitAccessRight.personalAccessRight)}"
                                                                        class="fa account-expander">
                                                                  </span>
                                                      </span>
                                      </td>
                                      <td>
                                        {{orgUnitAccessRight.personalAccessRight.accessElement.name}}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td st-hide="timeProfile">
                                  {{orgUnitAccessRight.personalAccessRight.timeProfile.name}}
                                </td>
                                <td st-hide="validFrom" class="access-rights-management-time-td">
                                   <span ng-show="vm.shouldShowEmptyDateField(orgUnitAccessRight.personalAccessRight.validFrom)">
                                    <br />
                                  </span>
                                  <span ng-hide="vm.shouldShowEmptyDateField(orgUnitAccessRight.personalAccessRight.validFrom)">
                                    <i class="fa fa-calendar"></i> {{orgUnitAccessRight.personalAccessRight.validFrom.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                                    <br />
                                    <i class="fa fa-clock-o"></i> {{orgUnitAccessRight.personalAccessRight.validFrom | date:'shortTime'}}
                                  </span>
                                </td>
                                <td st-hide="validTo" class="access-rights-management-time-td">
                                   <span ng-show="vm.shouldShowEmptyDateField(orgUnitAccessRight.personalAccessRight.validTo)">
                                    <br />
                                  </span>
                                  <span ng-hide="vm.shouldShowEmptyDateField(orgUnitAccessRight.personalAccessRight.validTo)">
                                    <i class="fa fa-calendar"></i> {{orgUnitAccessRight.personalAccessRight.validTo.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                                    <br />
                                    <i class="fa fa-clock-o"></i> {{orgUnitAccessRight.personalAccessRight.validTo | date:'shortTime'}}
                                  </span>
                                </td>
                                <td st-hide="grantedBy" class="access-rights-management-table-granted-by">
                                  <i class="fa fa-user"></i> {{orgUnitAccessRight.personalAccessRight.grantedBy}}
                                </td>
                                <td st-hide="grantedOn" class="access-rights-management-time-td">
                                  <i class="fa fa-calendar"></i> {{orgUnitAccessRight.personalAccessRight.grantedOn.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                                  <br />
                                  <i class="fa fa-clock-o"></i> {{orgUnitAccessRight.personalAccessRight.grantedOn | date:'shortTime'}}
                                </td>
                                <td st-hide="comment" class="access-rights-management-comment">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" title="{{orgUnitAccessRight.personalAccessRight.grantedWithComment}}">
                                      {{orgUnitAccessRight.personalAccessRight.grantedWithComment}}
                                    </span>
                                  </span>
                                </td>
                                <td st-hide="personProperty">
                                  {{orgUnitAccessRight.personalAccessRight.personProperty}}
                                </td>
                                <td st-hide="accessZone" align="center" valign="middle">
                                  <input type="checkbox" ng-model="orgUnitAccessRight.personalAccessRight.accessZoneActive"
                                         name="{{orgUnitAccessRight.personalAccessRight.guid}}"
                                         ng-disabled="true">
                                </td>
                                <td st-hide="active" align="center" valign="middle" class="access-right-management-table-active">
                                  <input type="checkbox" ng-model="orgUnitAccessRight.personalAccessRight.active"
                                         ng-change="vm.changeOrgUnitAccessRightActive(orgUnitAccessRight)"
                                         ng-disabled="!(orgUnitAccessRight.personalAccessRight.executionRightValue >= vm.executionRightValue.ChangePerm)">
                                </td>
                              </tr>
                              <!--Hidden Responsive Area-->
                              <tr ng-repeat-end ng-show="stFunc.showResponsiveRow() && stFunc.isRowOpened(orgUnitAccessRight.personalAccessRight) && !group.isClosed">
                                <td colspan="{{stFunc.colSpan()}}">

                                  <div class="hiddenResponsiveAreaPositionLeft">
                                    <div st-show="validFrom">
                                      <span><b> {{'ACCESS_RIGHT_TABLE_VALID_FROM' | translate}}: </b></span>
                                    </div>
                                    <div st-show="validTo">
                                      <span><b> {{'ACCESS_RIGHT_TABLE_VALID_TO' | translate}}: </b></span>
                                    </div>
                                    <div st-show="grantedBy">
                                      <span><b> {{'ACCESS_RIGHT_TABLE_GRANTED_BY' | translate}}: </b></span>
                                    </div>
                                    <div st-show="grantedOn">
                                      <span><b> {{'ACCESS_RIGHT_TABLE_GRANTED_ON' | translate}}: </b></span>
                                    </div>
                                    <div st-show="accessZone" class="hiddenResponsiveAreaAccessZoneAndComment">
                                      <span><b> {{'ACCESS_RIGHT_TABLE_ACCESS_ZONE' | translate}}: </b></span>
                                    </div>
                                    <div st-show="personProperty" class="hiddenResponsiveAreaAccessZoneAndComment" ng-hide="!orgUnitAccessRight.personalAccessRight.personProperty">
                                      <span><b> {{'ORG_UNIT_ACCESS_RIGHT_TABLE_PERSON_PROPERTY' | translate}}: </b></span>
                                    </div>
                                    <div st-show="comment" class="hiddenResponsiveAreaAccessZoneAndComment" ng-hide="!orgUnitAccessRight.personalAccessRight.grantedWithComment">
                                      <span><b> {{'ACCESS_RIGHT_TABLE_COMMENT' | translate}}: </b></span>
                                    </div>
                                  </div>

                                  <div class="hiddenResponsiveAreaPositionRight">
                                    <div st-show="validFrom">
                                      <span ng-hide="vm.shouldShowEmptyDateField(orgUnitAccessRight.personalAccessRight.validFrom)">
                                        <i class="fa fa-calendar"></i> {{orgUnitAccessRight.personalAccessRight.validFrom.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                                        <i class="fa fa-clock-o"></i> {{orgUnitAccessRight.personalAccessRight.validFrom | date:'shortTime'}}
                                      </span>
                                    </div>
                                    <div st-show="validTo">
                                      <span ng-hide="vm.shouldShowEmptyDateField(orgUnitAccessRight.personalAccessRight.validTo)">
                                        <i class="fa fa-calendar"></i> {{orgUnitAccessRight.personalAccessRight.validTo.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                                        <i class="fa fa-clock-o"></i> {{orgUnitAccessRight.personalAccessRight.validTo | date:'shortTime'}}
                                      </span>
                                    </div>
                                    <div st-show="grantedBy">
                                      <span>
                                        <i class="fa fa-user"></i> {{orgUnitAccessRight.personalAccessRight.grantedBy}}
                                      </span>
                                    </div>
                                    <div st-show="grantedOn">
                                      <span>
                                        <i class="fa fa-calendar"></i> {{orgUnitAccessRight.personalAccessRight.grantedOn.toLocaleDateString(vm.currentLanguage, {month: '2-digit', day: '2-digit', year: '2-digit'})}}
                                        <i class="fa fa-clock-o"></i> {{orgUnitAccessRight.personalAccessRight.grantedOn | date:'shortTime'}}
                                      </span>
                                    </div>
                                    <div st-show="accessZone">
                                      <input type="checkbox" ng-model="orgUnitAccessRight.personalAccessRight.accessZoneActive"
                                             name="{{orgUnitAccessRight.personalAccessRight.guid}}"
                                             ng-disabled="true">
                                    </div>
                                    <div st-show="personProperty" ng-hide="!orgUnitAccessRight.personalAccessRight.personProperty">
                                      <span> {{orgUnitAccessRight.personalAccessRight.personProperty}}</span>
                                    </div>
                                    <div st-show="comment" ng-hide="!orgUnitAccessRight.personalAccessRight.grantedWithComment">
                                      <span> {{orgUnitAccessRight.personalAccessRight.grantedWithComment}} </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr ng-repeat-end></tr>
                              <!--Show "No Data" if there are no access rights-->
                              <tr ng-if="!vm.accessRightsManagementService.displayedGroups.length">
                                <td align="center" colspan="10">
                                  <b>{{'LANG_NODATA' | translate}}</b>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                    </form>
                    </div>
            </div>
        </div>
    </div>
    <iflx-scroll-up-directive></iflx-scroll-up-directive>
  </section>
