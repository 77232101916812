<div class="iflx-widget-body AT_widget_terminal livetracking-widget-body">
    <livetracking-widget
      has-perm="hasPerm"
      terminal-status-from-angular="terminalStatusFromAngular"
      is-online="isOnline"
      is-edit="isEdit"
      is-mobile="isMobile"
      allow-location-tracking="allowLocationTracking"
      allow-only-bookings-with-location="allowOnlyBookingsWithLocation"
      current-person="currentPerson"
      activity-right="activityRight"
      is-used-in-new-startpage="false"
    />
</div>
