<div class="modal-header">
    <h3 class="modal-title"  ng-show="!vm.hasChanged">{{ 'ACCESS_RIGHT_MODAL_ADD_ACCESS_ELEMENT' | translate }}</h3>
    <h3 class="modal-title"  ng-show="vm.hasChanged">{{ 'ACCESS_RIGHT_MODAL_CHANGE_ACCESS_ELEMENT' | translate  }}</h3>
</div>

<div class="modal-body no-padding access-rights-management-modal" iflx-stop-propagation="touchend">
    <form class="smart-form client-form form-modal-less-padding">
        <fieldset class="no-padding">

            <!-- select valid from date and time -->
            <section>
                <div class="form-group">
                    <label class="label">{{'ACCESS_RIGHT_MODAL_VALID_FROM' | translate}}</label>
                    <iflx-date-range-picker ng-if="!vm.nativeMobilePicker"
                                            extended-options="datePickerOptions"
                                            time-picker="true"
                                            class="add-time-picker"
                                            single-date-picker="true"
                                            start-date="vm.selectedStartDateFrom"
                                            end-date="vm.selectedStartDateTo"
                                            allow-clear="true">
                    </iflx-date-range-picker>

                    <div ng-if="vm.nativeMobilePicker">
                        <input type="datetime-local" ng-model="vm.selectedStartDateTo"
                               ng-disabled="isOpenInterval">
                    </div>
                </div>
            </section>

            <!-- select valid to date and time -->
            <section>
                <div class="form-group">
                    <div>
                    <div class="access-rights-management-modal-valid-to-label">
                        <label class="label">
                        {{'ACCESS_RIGHT_MODAL_VALID_TO' | translate}}
                        </label>
                    </div>
                    <div class="access-rights-management-modal-valid-to-checkbox">
                        <input type="checkbox" checked="checked" ng-model="vm.unlimitedEndDate" >
                    </div>
                    <div class="access-rights-management-modal-valid-to-unlimited-label">
                        <label>{{'ACCESS_RIGHT_MODAL_VALID_TO_UNLIMITED' | translate}}</label>
                    </div>
                    </div>

                    <br>
                    <br>

                    <div class="access-rights-management-modal-valid-to-datepicker">
                    <iflx-date-range-picker ng-if="!vm.nativeMobilePicker && !vm.unlimitedEndDate" extended-options="datePickerOptions"
                                            time-picker="true" class="add-time-picker"
                                            single-date-picker="true" start-date="vm.selectedEndDateFrom"
                                            end-date="vm.selectedEndDateTo" minimum="vm.selectedStartDateTo"
                                            allow-clear="true">
                    </iflx-date-range-picker>
                    </div>

                    <div ng-if="vm.nativeMobilePicker && !vm.unlimitedEndDate">
                        <input type="datetime-local" ng-model="vm.selectedEndDateTo"
                                ng-disabled="isOpenInterval">
                    </div>
                </div>
            </section>

            <!-- select access element type -->
            <section>
                <div class="form-group">
                    <label class="label">{{ ::'ACCESS_RIGHT_MODAL_ACCESS_ELEMENT' | translate }}</label>
                    <div class="dropdown access-rights-management-dropdown-menu">
                        <button class="btn btn-default form-control padding-left-5 dropdown-button" type="button" data-toggle="dropdown">
                            <span ng-if="vm.selectedAccessElement.typeId != undefined"> {{vm.selectedAccessElement.name | translate}}</span>
                            <span ng-if="vm.selectedAccessElement.typeId == undefined"> {{'SELECT_PLACEHOLDER' | translate}}</span>
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu access-rights-management-dropdown-menu">
                            <li role="presentation" ng-repeat="accessElement in vm.accessRightsManagementService.accessElementTypes">
                                <a tabindex="-1"
                                   ng-click="vm.setAccessElementType(accessElement)" class="dropdown-access-rights-management-li">
                                    {{ accessElement.name | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <!-- select access element -->
            <section>
                <div class="form-group access-rights-management-modal-access-element-picker">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div iflx-picker
                             ng-model="vm.accessElement"
                             class="corrections-employee-input add-access-element-picker"
                             data-picker-id="accessElementPicker"
                             data-picker-disabled="!vm.selectedAccessElement.name"
                             data-type="accesselement"
                             data-multi-selection="false"
                             data-search-auto-focus="false"
                             data-combined-result="false"
                             data-sync-with-person-service="false"
                             data-persistent="false"
                             data-on-selection="vm.onPickerSelection"
                             data-activity-right="vm.accessRightsManagementService.activityRight"
                             data-init-value="vm.accessElement"
                             data-listen-for-clear-message="true"
                             data-options="{
                                 accessElement: vm.selectedAccessElement, 
                                 person: vm.accessRightsManagementService.selectedEmployee.employeeGuid,
                                 periodBegin: vm.periodBegin,
                                 periodEnd: vm.periodEnd,
                                 period: vm.period
                                }">
                        </div>
                    </div>
                </div>
            </section>

            <!-- select time profile -->
            <section>
                <div class="form-group">
                    <label class="label">{{ 'ACCESS_RIGHT_MODAL_TIME_PROFILE' | translate }}</label>
                    <div class="dropdown access-rights-management-dropdown-menu" >
                        <button class="btn btn-default form-control padding-left-5 dropdown-button" type="button" data-toggle="dropdown" ng-disabled="vm.dontShowTimeProfile || !vm.selectedAccessElement.typeId || vm.accessRightsManagementService.timeProfilesResponse.timeProfiles.length < 1">
                            <span ng-if="vm.timeProfile !== undefined"> {{vm.timeProfile.name}}  |  {{vm.timeProfile.description}}</span>
                            <span ng-if="vm.timeProfile == undefined"> {{'SELECT_PLACEHOLDER' | translate}}</span>
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu access-rights-management-dropdown-menu">
                            <li role="presentation" ng-repeat="timeProfile in vm.accessRightsManagementService.timeProfilesResponse.timeProfiles | orderBy: 'number'">
                                <a tabindex="-1"
                                   ng-click="vm.selectTimeProfile(timeProfile)" class="dropdown-access-rights-management-li">
                                    {{timeProfile.name}}  |  {{timeProfile.description}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <!-- select additional option -->
            <section>
              <div class="form-group">
                <div class="access-rights-management-modal-more-options-label">
                  <label class="label">{{ 'ACCESS_RIGHT_MODAL_MORE_OPTIONS' | translate }}</label>
                </div>
                <div>
                    <input type="checkbox" checked="checked" ng-model="vm.active" >
                    <label class="access-rights-management-modal-checkboxes">{{ 'ACCESS_RIGHT_MODAL_IS_ACTIVE' | translate }}</label>
                </div>
                <div>
                    <input type="checkbox" ng-model="vm.accesZoneActive" >
                    <label>{{ 'ACCESS_RIGHT_MODAL_ACCESS_ZONE' | translate }}</label>
                </div>
              </div>
            </section>

            <br>

            <!-- enter comment -->
            <section>
              <div class="form-group">
                  <label class="label" >{{ 'ACCESS_RIGHT_MODAL_COMMENT' | translate }}</label>
                  <textarea id="personal-access-right-comment" class="form-control" rows="3"
                             ng-model="vm.comment"></textarea>
              </div>
            </section>

        </fieldset>
    </form>
</div>

<div class="modal-footer picker-modal-footer">
    <button type="button" class="btn btn-success" ng-click="vm.createNewAccessRight()"
            ng-disabled="!vm.isEverythingMandatorySelected()"
            ng-show="!vm.hasChanged">
        <span>{{ 'ACCESS_RIGHTS_MODAL_BUTTON_ADD_ACCESS_RIGHT' | translate}}</span>
    </button>

    <button type="button" class="btn btn-success" ng-click="vm.changeAccessRight()"
            ng-show="vm.hasChanged" ng-disabled="!vm.isEverythingMandatorySelected()">
        <span>{{ 'ACCESS_RIGHT_MODAL_BUTTON_CHANGE_ACCESS_RIGHT' | translate}}</span>
    </button>

    <button class="btn btn-default" ng-click="vm.closeModal()" ng-class="{ disabled: addBtnDisabled }">{{'LANG_CANCEL' | translate}}</button>
</div>
