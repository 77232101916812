<section iflx-widget-grid-directive id="account-corrections-widget-grid" class="account-corrections-view">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

            <div data-jarvis-widget class="jarviswidget jarviswidget-color-darken" id="wid-account-corrections-0"
                 data-widget-colorbutton="false"
                 data-widget-editbutton="false"
                 data-widget-togglebutton="false"
                 data-widget-deletebutton="false"
                 data-widget-custombutton="false"
                 data-widget-collapsed="false"
                 data-widget-sortable="false">

                <header data-widget-fullscreenbutton="true">
                    <div class="jarviswidget-ctrls" role="menu">
                        <a ng-click="vm.refreshAllData()" class="button-icon" rel="tooltip"
                           refresh-button
                           data-placement="bottom" data-original-title="{{'MAIN_CONTROLS_REFRESH' | translate }}">
                            <i class="fa fa-refresh"></i>
                            <span visually-hidden>{{'MAIN_CONTROLS_REFRESH' | translate }}</span>
                        </a>
                    </div>

                    <span class="widget-icon" aria-hidden="true">
                    <i class="iflx-font iflx-font-inline iflx-font-kontokorrekturen"></i>
                    </span>

                    <h2>{{'ACCOUNT_CORRECTION_HEADER' | translate}}</h2>

                    <ul class="nav pull-right nav-pills nav-line-normal">
                        <li ui-sref-active="active">
                            <a ui-sref="root.eVAYO.accountCorrections.accounts">
                                <i class="iflx-font iflx-font-tab iflx-font-kontokorrekturen"></i>
                                <span class="hidden-mobile hidden-tablet">
                                    {{'ACCOUNT_CORRECTION_ACCOUNTS' | translate}}
                                </span>
                            </a>
                        </li>
                        <li ui-sref-active="active">
                            <a ui-sref="root.eVAYO.accountCorrections.transactions">
                                <i class="iflx-font iflx-font-tab iflx-font-kontoumbuchung"></i>
                                <span class="hidden-mobile hidden-tablet">
                                    {{'ACCOUNT_CORRECTION_TRANSACTIONS' | translate}}
                            </span>
                            </a>
                        </li>
                    </ul>

                </header>

                <div>
                    <div class="widget-body">
                        <div class="tab-content">
                            <ui-view></ui-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <iflx-scroll-up-directive></iflx-scroll-up-directive>
</section>
