<ifr-page-title pageTitle="'LANG_LOGIN_PAGE_TITLE' | translate " />

<page-header
ng-if="!vm.shouldShowOnlyLoadingSpinner"
  is-login-page="true"
  menu-state-change-callback="vm.menuStateChangeListener"
/>

<div class="login-layout">
  <login-menu
  ng-if="!vm.shouldShowOnlyLoadingSpinner"
    set-temporal-language="vm.setTemporalLanguage"
    languages="vm.$scope.languages"
    selected-lang-full-code="vm.getSelectedLangFullCode()"
    skins="vm.$scope.design"
    change-skin="vm.changesSkin"
    selected-skin-id="vm.getSelectedSkinId()"
  />


  <main class="animated fadeInDown main">
    <div class="pull-right text-italic txt-color-lightgray margin-5 font-sm hidden-xs hidden-sm">
      <app-version/>
    </div>

    <div id="content">
      <login-container
        get-is-oidc-state-valid="vm.getIsOidcStateValid"
        get-is-oidc-nonce-valid="vm.getIsOidcNonceValid"
        is-oidc="oidc"
        is-ssoad="ssoad"
        on-login-submit="vm.submitLogin"
        on-password-change="vm.changePassword"
        should-show-only-loading-spinner="vm.shouldShowOnlyLoadingSpinner"
      />
    </div>
  </main>
</div>
