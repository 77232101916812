<div class="widget-body-toolbar corrections-toolbar">
    <div class="row" iflx-affix="140" adjust-to=".corrections-affix" enable-mobile="false">
        <!-- Date selection -->
        <div class="corrections-md-margin-bottom col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <iflx-date-range-picker
                    single-date-picker="true"
                    initial-callback="true"
                    start-date="vm.selectedDate"
                    end-date="vm.selectedDate"
                    show-arrows="true">
            </iflx-date-range-picker>
        </div>

        <!-- Employee Selection -->
        <div class="corrections-md-margin-bottom col-xs-12 col-sm-12 col-md-12 col-lg-3" ng-hide="vm.isSimpleUser">
            <iflx-picker
                    class="corrections-employee-input"
                    data-picker-id="accountCorrectionsAccountsPicker"
                    data-type="person"
                    data-multi-selection="false"
                    data-search-auto-focus="false"
                    data-combined-result="false"
                    data-sync-with-person-service="true"
                    data-persistent="true"
                    data-on-selection="vm.onPersonSelection"
                    data-activity-right="vm.activityRight"
                    data-search-context="accounting"
                    data-with-personnel-number="true"/>
        </div>

        <!-- Search criteria -->
        <div class="corrections-md-margin-bottom col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-search"></i></span>
                <input class="form-control ie-height-fix" type="text"
                       ng-model="searchCriteria" ng-change="vm.saveSearchCriteria()">

                <span class="input-remove-icon" ng-click="vm.clearInput()">
                    <i ng-show="!!searchCriteria" class="fa fa-times"></i>
                </span>
            </div>
        </div>

        <!-- Button area -->
        <div class="col-xs-12 col-sm-12 col-md-12 text-right"
             ng-class="{'col-lg-6': vm.isSimpleUser,
                        'col-lg-3': !vm.isSimpleUser}">
            <button ng-click="vm.changeAccountValues()"
                    ng-class="{'disabled': !vm.readyToStore() || vm.storingInProcess,'btn-success': vm.readyToStore()}"
                    class="btn btn-sm">
                <i class="fa fa-check"></i>
                {{'BUTTON_SAVE' | translate}}
            </button>
        </div>
    </div>
</div>

<form name="accountsForm" class="accountCorrectionsForm" unsaved-warning-form>
    <div class="account-corrections-accounts">
        <table st-table="vm.displayAccounts"
               st-responsive="vm.responsiveOptions"
               st-safe-src="vm.accounts"
               st-class-ascent="st-sort-ascent"
               st-class-descent="st-sort-descent"
               st-func="stFunc"
               id="accounts-table"
               class="table table-bordered dataTable hover table-hover account-corrections-view-table"
               width="100%">
            <thead>
            <tr>
                <th st-hide="sourceAccount" st-sort="sourceAccount">
                    {{'ACCOUNT_CORRECTION_SOURCEACCOUNT' | translate}}
                </th>
                <th st-hide="label" st-sort="label">{{'ACCOUNT_CORRECTION_TARGETACCOUNT'
                    | translate}}
                </th>
                <th st-hide="currentValue">
                    <nobr>{{'ACCOUNT_CORRECTION_ACCOUNT_CURRENT_VALUE' | translate}}
                    </nobr>
                </th>
                <th st-hide="unit">{{'ACCOUNT_CORRECTION_UNIT' | translate}}</th>
                <th st-hide="newValue">
                    <nobr>
                        {{'ACCOUNT_CORRECTION_ACCOUNT_NEW_VALUE' | translate}}
                        <span iflx-context-help="ACCOUNT_CORRECTION_VALUE_HELP"></span>
                    </nobr>
                </th>

                <th st-hide="comment" class="account-corrections-comment">
                    {{'ACCOUNT_CORRECTION_ACCOUNT_COMMENT' | translate}}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat-start="account in vm.displayAccounts |
                    filter: vm.searchCriteriaFilter(searchCriteria) |
                    filter: vm.targetAccountFilter(account)"
                ng-class="{'row-focus': account == vm.selectedAccount}"
                ng-click="vm.onRowClick(account, $event)">
                <td st-hide="sourceAccount" class="account-corrections-source-input-cell">
                    <table>
                        <tr>
                            <td>
                                <!-- Expander icon -->
                                <span ng-show="stFunc.showResponsiveRow()"
                                      ng-click="stFunc.toggleRowOpened(account)">
                                                        <span ng-class="{'fa-minus-square-o': $parent.stFunc.isRowOpened(account),
                                                        'fa-plus-square-o': !$parent.stFunc.isRowOpened(account)}"
                                                              class="fa account-expander">
                                                        </span>
                                </span>
                            </td>
                            <td>
                                <iflx-combobox
                                        items="vm.displayAccounts"
                                        selected-item="account.sourceAccount"
                                        custom-filter-func="vm.sourceAccountFilter(account)"
                                        input-css="account-corrections-source-input">
                                </iflx-combobox>
                                <div class="account-corrections-source-input-sourcevalue">
                                    <span ng-if="account.sourceAccount != undefined && vm.service.isHourUnit(account.sourceAccount)">
                                        ({{account.sourceAccount.value}})
                                    </span>
                                    <span ng-if="account.sourceAccount != undefined && !vm.service.isHourUnit(account.sourceAccount)">
                                        ({{account.sourceAccount.value  | iflxNumber : 2 }})
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </table>

                </td>
                <td st-hide="label">
                    {{account.label}}

                </td>
                <td st-hide="currentValue" ng-click="vm.focusInput(row, $event)">

                    <input ng-if="vm.service.isHourUnit(account)"
                           class="form-control account-corrections-account account-corrections-disabled no-ms-clear-icon"
                           type="text"
                           value="{{ account.value }}" disabled="disabled">

                    <input ng-if="!vm.service.isHourUnit(account)"
                           class="form-control account-corrections-account account-corrections-disabled no-ms-clear-icon"
                           type="text"
                           value="{{ account.value | iflxNumber : 2 }}"
                           disabled="disabled">
                </td>
                <td st-hide="unit">
                    {{account.unit}}
                </td>
                <td class="visible-input" st-hide="newValue" ng-click="vm.focusInput(account, $event)">
                    <div class="padding-left-right-6">
                        <input ng-if="vm.service.isHourUnit(account)"
                               iflx-account-input
                               class="account-corrections-account no-ms-clear-icon"
                               ng-model="account.inputValue"
                               type="text"
                               ng-change="vm.setChangedValues({_account:account})"
                               value="">

                        <input ng-if="!vm.service.isHourUnit(account)"
                               iflx-decimal-input
                               class="account-corrections-account no-ms-clear-icon"
                               ng-model="account.inputValue" type="text"
                               ng-change="vm.setChangedValues({_account:account})"
                               value="">
                    </div>
                </td>
                <td class="visible-input" st-hide="comment" ng-click="vm.focusInput(account, $event)">
                    <div class="padding-left-right-6">
                        <input class="no-ms-clear-icon" placeholder=""
                               ng-model="account.comment" type="text"
                               ng-change="vm.addToChangedAccounts(account)"
                               value="">
                    </div>
                </td>
            </tr>

            <!-- Hidden Responsive Area-->
            <tr ng-repeat-end class="responsive-row"
                ng-show="stFunc.showResponsiveRow() && stFunc.isRowOpened(account)">
                <td colspan="{{stFunc.colSpan()}}">

                    <div st-show="currentValue">
                      <p class="responsive-headline">{{'ACCOUNT_CORRECTION_ACCOUNT_CURRENT_VALUE' | translate}}</p>
                      <span ng-if="vm.service.isHourUnit(account)">{{ account.value }} {{account.unit}} </span>
                      <span ng-if="!vm.service.isHourUnit(account)">{{ account.value | iflxNumber : 2 }} {{account.unit}} </span>
                    </div>

                     <div st-show="newValue">
                       <p class="responsive-headline">{{'ACCOUNT_CORRECTION_ACCOUNT_NEW_VALUE' | translate}}</p>

                            <input ng-if="vm.service.isHourUnit(account)"
                                   iflx-account-input
                                   class="form-control account-corrections-account no-ms-clear-icon visible-input"
                                   ng-model="account.inputValue"
                                   type="text"
                                   ng-change="vm.setChangedValues({_account:account})"
                                   value="">

                            <input ng-if="!vm.service.isHourUnit(account)"
                                   iflx-decimal-input
                                   class="form-control account-corrections-account no-ms-clear-icon visible-input"
                                   ng-model="account.inputValue" type="text"
                                   ng-change="vm.setChangedValues({_account:account})"
                                   value="">
                    </div>

                    <div st-show="comment">
                      <p class="responsive-headline">{{'ACCOUNT_CORRECTION_ACCOUNT_COMMENT' | translate}}</p>
                        <input class="form-control no-ms-clear-icon visible-input" placeholder=""
                               ng-model="account.comment" type="text"
                               ng-change="vm.addToChangedAccounts(account)"
                               value="">
                    </div>
                </td>
            </tr>
            <tr ng-if="!vm.accountValuesLoaded()">
                <td align="center" colspan="10">
                    <b>{{'ACCOUNT_CORRECTION_NO_ACCOUNT_VALUES' | translate}}</b>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</form>
