<div class="modal-header">
    <h3 class="modal-title">{{'ACCOUNT_CORRECTION_TRANSACTION_DELETE_CONFIRMATION_MODAL_HEADER' | translate}}</h3>
</div>
<div class="modal-body no-padding" iflx-stop-propagation="touchend">
    <form id="account-corrections-transactions-delete-confirm-form"
          name="accountCorrectionsTransactionsDeleteConfirmForm"
          class="form-horizontal form-modal-less-padding">

        <!--- todo -->
        <fieldset ng-disabled="isOffline" class="no-padding">

            <!-- User -->
            <div class="form-group account-corrections-transactions-modal-no-margin-bottom">
                <label class="col-md-2">
                    <b><span>{{'ACCOUNT_CORRECTION_TRANSACTION_DELETE_CONFIRMATION_PERSON' | translate}}:</span></b>
                </label>

                <div class="col-md-10">
                    <span class="fa fa-user"></span>
                    <span>{{vm.requestForPerson}}</span>
                </div>
            </div>

            <!-- Date -->
            <div class="form-group">
                <label class="col-md-2">
                    <b><span>{{'ACCOUNT_CORRECTION_TRANSACTION_DELETE_CONFIRMATION_DATE' | translate}}:</span></b>
                </label>

                <div class="col-md-10">
                    <span>{{vm.requestForDate | date: 'dd.MM.yyyy'}}</span>
                </div>
            </div>

            <!-- Transactions -->
            <div class="form-group col-md-12">
                <!-- Label -->
                <label>
                    <b><span>{{'ACCOUNT_CORRECTION_TRANSACTION_DELETE_CONFIRMATION_TRANSACTIONS_LABEL' | translate}}:</span></b>
                </label>

                <!-- Transactions Details -->
                <div class="account-corrections-transactions-modal-transactions-list">
                    <div ng-repeat="transaction in vm.requestedTransactions">
                        <span>{{transaction.sourceAccount}}</span>
                        <span class="fa fa-arrow-right"></span>
                        <span>{{transaction.targetAccount}}</span>
                        <span ng-if="vm.service.isHourUnit( transaction )">({{transaction.amount}} {{transaction.unit}})</span>
                        <span ng-if="!vm.service.isHourUnit( transaction )">({{transaction.amount | iflxNumber : 2}} {{transaction.unit}})</span>
                    </div>
                </div>
            </div>

            <!-- Remark -->
            <textarea class="form-control" placeholder="{{'ACCOUNT_CORRECTION_TRANSACTION_DELETE_CONFIRMATION_REMARK' | translate}}"
                    ng-model="vm.form.remark"></textarea>

        </fieldset>
    </form>
</div>
<div class="modal-footer">
    <div type="button" class="btn btn-success"
         ng-click="vm.confirmDeleteTransactions()">
        <span>{{'BUTTON_APPLY' | translate}}</span>
    </div>
    <div type="button" class="btn btn-default" ng-click="vm.dismissModal()">
        {{'BUTTON_CANCEL' | translate}}
    </div>
</div>
