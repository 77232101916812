<ifr-page-title pageTitle="'LEFT_PANEL_DASHBOARD' | translate "/>
<div class="jarviswidget-color-darken main-dashboard" data-jarvis-widget id="dashboard-main-jarvis-widget-id">
    <header>
        <span class="widget-icon" aria-hidden="true"> <i class="iflx-font iflx-font-inline iflx-font-logo-interflex"></i></span>
        <h1>{{'Dashboard' | translate}}</h1>

        <div class="main-dashboard-header-buttons" ng-show="vm.allowDashBoardChanges">
            <button ng-hide="vm.isEdit" ng-click="vm.toggleEdit()" class="accessibility-button button-like-link fas fa-edit edit-button">
                <span visually-hidden>{{'ACCESSIBILITY_DASHBOARD_EDITING_START' | translate}}</span>
            </button>
            <button ng-show="vm.isEdit" ng-click="vm.toggleEdit()" class="accessibility-button button-like-link fas fa-check-square edit-button">
                <span visually-hidden>{{'ACCESSIBILITY_DASHBOARD_EDITING_SAVE' | translate}}</span>
            </button>
        </div>
    </header>

    <div ng-show="vm.dashBoardSettingsMissing" class="center-text bold">
        <span>{{'DASHBOARD_SETTINGS_MISSING' | translate}}</span>
    </div>

    <div id="dashboard-body" ng-show="!vm.dashBoardSettingsMissing">
        <!-- widget grid -->
        <section data-widget-grid id="widget-grid">
            <div class="row">

                <!-- NEW WIDGET START -->
                <article ng-class="{'col-sm-6': !isMainMenuVisible}" class="col-xs-12 col-md-6 col-lg-6">
                    <div ng-repeat="widget in widgets.col1" iflx-widget-container widget-info="widget"
                         is-edit="vm.isEdit"></div>
                </article>
                <!-- WIDGET END -->

                <!-- NEW WIDGET START -->
                <article ng-class="{'col-sm-6': !isMainMenuVisible}" class="col-xs-12 col-md-6 col-lg-6">
                    <!-- do not delete, this is a hidden widget for initialization of second row-->
                    <!--<div class="jarviswidget jarviswidget-color-blue" id="initialization-hidden-widget">-->

                    <!--</div>-->
                    <div ng-repeat="widget in widgets.col2" iflx-widget-container
                         widget-info="widget" is-edit="vm.isEdit"></div>

                </article>
                <!-- WIDGET END -->
            </div>

            <!-- end row -->
        </section>
    </div>
    <iflx-scroll-up-directive></iflx-scroll-up-directive>
</div>
