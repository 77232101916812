<div ng-if="options.type != 'logout'" class="favorite-widget-body iflx-widget-body AT_widget_favorite"
     ng-class="{'no-permission-background':!hasPerm()}">
    <div ng-class={'no-permission':!hasPerm(),'no-background':hasPerm()}>
        <i class="favoriteWidget cursor-pointer" ng-class="icon" ng-click="goToUrl()"></i>

        <p class="no-margin">

        <div class="alignCenter">
            <span class="no-permission-font" ng-if="!hasPerm()">{{'UNAUTHORIZED_MESSAGE_WIDGET' | translate}}</span>
            <a class="link-like-text favorite-name" ng-click="goToUrl()">
                {{ name | translate}}
            </a>
        </div>
        </p>
    </div>
</div>


<div ng-if="options.type == 'logout'" logout-click disabled="{{isEdit}}" class="favorite-widget-body iflx-widget-body AT_widget_logout">
    <div class="no-background">
        <i class="favoriteWidget cursor-pointer" ng-class="icon"></i>

        <p class="no-margin">

        <div class="alignCenter"><span>{{ name | translate}}</span></div>
        </p>
    </div>
</div>
